import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import 'react-bootstrap-carousel/dist/react-bootstrap-carousel.css'
import Container from 'react-bootstrap/Container'
import Header from './Components/Header'
import { Card, Col, Row, Button, Toast } from 'react-bootstrap'
import { functions, httpsCallable } from './firebase'

function Dashboard (props) {
  const [showToast, setShowToast] = useState(false)
  const handleClick = () => {
    window.open('https://us-central1-keystone-5fb7e.cloudfunctions.net/GetCustomerListAsCSV')
  }
  const scheduleEmails = httpsCallable(functions, 'scheduleEmails')
  return <Container>
    <Col md={{ span: 4, offset: 4 }}>
    <Toast animation={false} onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide={false}>
      <Toast.Header>
        <strong className="me-auto">Emails</strong>
        <small>now</small>
      </Toast.Header>
      <Toast.Body>Hello, The Emails are sent to all customers.</Toast.Body>
    </Toast>
    </Col>
    <Header/>
    <Row className="row-margin" xs="auto">
      <h5>
        Dashboard
      </h5>
      <Col>
        <Card style={{ width: '18rem' }}>
          <Card.Header>Download Latest Report</Card.Header>
          <Card.Body>
            <Card.Title>Report</Card.Title>
            <Card.Text>
              This will download the customers list and their latest payment status.
            </Card.Text>
            <Button variant="primary" onClick={(e) => handleClick()}>Download</Button>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card style={{ width: '18rem' }}>
          <Card.Header>Send Email to Customers</Card.Header>
          <Card.Body>
            <Card.Title>Email</Card.Title>
            <Card.Text>
              This will send emails to all the customers.
            </Card.Text>
            <Button variant="primary" onClick={(e) => {
              scheduleEmails()
              setShowToast(true)
            } }>Send Now</Button>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
}

export default Dashboard


