import { Col, Row } from 'react-bootstrap'
import logo from "../Images/quad_logo.jpg";
import React from "react";

function Header() {
    return <Row>
        <Col>
            <img className='logo' src={logo} alt="logo" style={{ maxWidth: '12rem' }}/>
            <div className='company-name'>The Quad</div>
        </Col>
    </Row>
}

export default Header