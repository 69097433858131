import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import {initializeApp} from "firebase/app";
import {getFirestore, doc, setDoc, getDoc, getDocs, collection, Timestamp} from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions'

const firebaseConfig = {
  apiKey: "AIzaSyD4ziTXxpJhAH5OjRQe5TJStec1XjSVJrM",
  authDomain: "keystone-5fb7e.firebaseapp.com",
  projectId: "keystone-5fb7e",
  storageBucket: "keystone-5fb7e.appspot.com",
  messagingSenderId: "156543134035",
  appId: "1:156543134035:web:f84aaf0ca3f720344459a6",
  measurementId: "G-9DYZC764EJ"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();
const functions = getFunctions(app)

const signInWithEmailPass = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  auth.signOut();
};

export {
  auth,
  signInWithEmailPass,
  logout,
  doc,
  getDoc,
  setDoc,
  getDocs,
  collection,
  db,
  functions,
  Timestamp,
  httpsCallable
};
