import Container from 'react-bootstrap/Container'
import React from 'react'
import Header from './Header'
import BodyContent from './BodyContent'
import CustomerForm from './CustomerForm'
import UserDetails from './UserDetails'
import { Col, Row } from 'react-bootstrap'
import CustomerPaid from './CustomerPaid'

function CustomerDetails (props) {
  return <Container>
    <Header/>
    <Row>
      <UserDetails data={props.data}/>
      {props.data.isAdmin ? <></> : <BodyContent data={props.data}/>}
    </Row>
    {(!props.data.isAdmin && props.data.userData.isPaid) ? <CustomerPaid/> :
    <Row className="row-margin">
      <Col>
        <center><h5>
          Payment Details
        </h5></center>
        <CustomerForm data={props.data}/>
      </Col>
    </Row>}
  </Container>
}

export default CustomerDetails