import { Col, Row } from 'react-bootstrap'
import React from 'react'

function UserDetails(props) {
  console.log(props)
  return <Row className="row-margin">
    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
      <h5>
        User Details
      </h5>
      <Col>Email : {props?.data?.userData?.email}</Col>
      <Col>Name : {props?.data?.userData?.name}</Col>
      <Col>Mobile : {props?.data?.userData?.phone}</Col>
    </Col>
  </Row>
}

export default UserDetails