import { Col, Form, Row } from 'react-bootstrap'
import React from 'react'

function InterestedForm (isAdminOverride, setDisplayFlag, setEnrolled) {
  return <Row className="row-margin">
    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
      <div>Are you in for the next Quater?</div>
    </Col>
    <Col xl={8} lg={8} md={8} sm={12} xs={12}>
      <div className="mb-3">
        <Form.Check
          defaultChecked
          inline
          label="Yes"
          name="group1"
          type="radio"
          className="form-in-line"
          disabled={isAdminOverride}
          onClick={() => {setEnrolled("Yes"); setDisplayFlag(true)}}
        />
        <Form.Check
          inline
          label="Not Sure"
          name="group1"
          type="radio"
          className="form-in-line"
          disabled={isAdminOverride}
          onClick={() => {setEnrolled("Not Sure"); setDisplayFlag(false)}}
        />
        <Form.Check
          inline
          label="I'm Out"
          name="group1"
          type="radio"
          className="form-in-line"
          disabled={isAdminOverride}
          onClick={() => {setEnrolled("I'm out"); setDisplayFlag(false)}}
        />
      </div>
    </Col>
  </Row>
}

export default InterestedForm