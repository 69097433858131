import {Row} from "react-bootstrap";
import _ from 'lodash'
const HtmlToReactParser = require('html-to-react').Parser;


function BodyContent(props) {
    const currentLocation = _.find(props?.data?.locations, x => x.value === props.data?.userData?.center);
    const htmlInput = currentLocation.emailContent;

    const htmlToReactParser = new HtmlToReactParser();
    const reactElement = htmlToReactParser.parse(htmlInput);

    return <Row className='row-margin'>
        <p>{reactElement}</p>
    </Row>
}

export default BodyContent