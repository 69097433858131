import React from 'react'
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import AdminEdit from "./Components/AdminEdit";

function App(props) {
  const userId = props.match.params.id
  return (
      <AdminEdit data={{userId, isAdmin : false}}/>
  )
}

export default App


