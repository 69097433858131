import React, { useEffect, useState } from 'react'
import "bootstrap/dist/css/bootstrap.css";
import { useHistory } from "react-router-dom";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, signInWithEmailPass } from './firebase'
import "./login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading] = useAuthState(auth);
  const history = useHistory();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) history.replace("/admin");
  }, [user, loading, history]);
  return (
    <div className="login">
      <div className="login__container">
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button
          className="login__btn"
          onClick={() => signInWithEmailPass(email, password)}
        >
          Login
        </button>
        {/*<div>*/}
        {/*  <Link to="/reset">Forgot Password</Link>*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  Don't have an account? <Link to="/register">Register</Link> now.*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export default Login


