import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router-dom";
import { auth } from "./firebase"
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import AdminEdit from "./Components/AdminEdit";

function Admin(props) {
    const userId = props.match.params.id
    const [user, loading] = useAuthState(auth);
    const history = useHistory();
    useEffect(() => {
        if (loading) return;
        if (!user) return history.replace("/login");
    }, [user, loading, history]);
    return (
        user ? <AdminEdit data={{userId, isAdmin : true}}/> : <></>
    )
}

export default Admin


