import React, {useEffect, useState} from 'react';
import './CustomerPage.css';
import "react-datepicker/dist/react-datepicker.css";
import CustomerDetails from "./CustomerDetails";
import { collection, db, doc, getDoc, getDocs } from '../firebase'

function AdminEdit(props) {
    const [userData, setUserData] = useState()
    const [locations, setLocations] = useState()
    const [plans, setPlans] = useState()
    const [batches, setBatches] = useState()

    useEffect(() => {
        if (!userData) {
            getData().then(() => {});
            if (!locations) {
                getAllDocs("location").then(r => {
                    setLocations(r)
                    if (!plans) {
                        getAllDocs("plan").then(r => {
                            setPlans(r)
                            if (!batches) {
                                getAllDocs("batch").then(r => {
                                    setBatches(r)
                                });
                            }
                        });
                    }
                });
            }
        }
    });

    const getAllDocs = async (docName) => {
        const querySnapshot = await getDocs(collection(db, docName))
        const centerValues = []
        querySnapshot.forEach((doc) => {
            centerValues.push(doc.data());
        })
        return centerValues
    }

    const getData = async () => {
        const docRef = doc(db, "customer", props.data.userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setUserData(docSnap.data())
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }

    }
    return batches ? <CustomerDetails data={{userData, locations, batches, plans, isAdmin : props.data.isAdmin}}/> : <></>
}

export default AdminEdit