import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Admin from './Admin';
import App from './App';
import Dashboard from './Dashboard'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from './login'

ReactDOM.render(
  <React.StrictMode>
      <Router>
          <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/user/:id" component={App} />
              <Route path="/admin/:id" component={Admin} />
              <Route path="/admin" component={Dashboard} />
          </Switch>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
