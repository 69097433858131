import { Col, Container, Form, Row } from 'react-bootstrap'
import React from 'react'
import Select from 'react-select'
import Button from 'react-bootstrap/Button'
import DatePicker from 'react-datepicker'

function PaymentDetails (currentNotes, handleChangeNotes, isPaid,
  handleChangePaid, currentPaymentMode, handleChangePaymentMode,
  paymentModes, handleSubmit, paidDate, setPaidDate, shouldEmail, handleChangeShouldEmail) {
  return <>
    <Row>
      <Col lg={6} xl={6} md={6} sm={12} xs={12}>
        <Row className="row-margin">
          <Col className="column-center-display" lg={6} xl={6} md={6} sm={12} xs={12}>
            <Form.Label>Send Email Link</Form.Label>
          </Col>
          <Col lg={6} xl={6} md={6} sm={12} xs={12}>
            <Form.Check
              defaultChecked={shouldEmail}
              disabled={isPaid}
              type={'checkbox'}
              id={`mark-paid`}
              onChange={handleChangeShouldEmail}
            />
          </Col>
        </Row>
      </Col>
      <Col lg={6} xl={6} md={6} sm={12} xs={12}>
        <Row className="row-margin">
          <Col className="column-center-display" lg={6} xl={6} md={6} sm={12} xs={12}>
            <Form.Label>Paid</Form.Label>
          </Col>
          <Col lg={6} xl={6} md={6} sm={12} xs={12}>
            <Form.Check
              defaultChecked={isPaid}
              disabled={shouldEmail}
              type={'checkbox'}
              id={`mark-paid`}
              onChange={handleChangePaid}
            />
          </Col>
        </Row>
      </Col>
      {isPaid ?
        <>
          <Col lg={6} xl={6} md={6} sm={12} xs={12}>
            <Row className="row-margin">
              <Col lg={6} xl={6} md={6} sm={12} xs={12}>
                <Form.Label>Payment Mode</Form.Label>
              </Col>
              <Col lg={6} xl={6} md={6} sm={12} xs={12}>
                <Select
                  defaultValue={currentPaymentMode}
                  value={currentPaymentMode}
                  onChange={handleChangePaymentMode}
                  options={paymentModes}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={6} xl={6} md={6} sm={12} xs={12}>
            <Row className="row-margin">
              <Col className="column-center-display" lg={6} xl={6} md={6} sm={12} xs={12}>
                <Form.Label>Paid On</Form.Label>
              </Col>
              <Col lg={6} xl={6} md={6} sm={12} xs={12}>
                <DatePicker selected={paidDate} onChange={(date) => setPaidDate(date)}/>
              </Col>
            </Row>
          </Col>
        </>
        : <></>}
      <Col lg={6} xl={6} md={6} sm={12} xs={12}>
        <Row className="row-margin">
          <Col lg={6} xl={6} md={6} sm={12} xs={12}>
            <Form.Label>Notes</Form.Label>
          </Col>
          <Col lg={6} xl={6} md={6} sm={12} xs={12}>
            <Form.Control
              type="text"
              placeholder="Notes"
              value={currentNotes}
              onChange={handleChangeNotes}
            />
          </Col>
        </Row>
      </Col>
      <Container className="bottom-padding row-margin" lg={6} xl={6} md={6} sm={12} xs={12}>
        <Button type="submit" className="button-display" variant="primary"
                onClick={(e) => handleSubmit(e)}>Update</Button>
      </Container>
    </Row>
  </>
}

export default PaymentDetails